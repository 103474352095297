/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import DebitCard from "../../debitCard/DebitCard";
import { countryList } from "../../constants/countries";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { cardPaymentSchema } from "./helper";
import "./style.css";
import { api_checkout_invoice_url } from "../constants";
import { useSearchParams } from "react-router-dom";
import { errorNotification } from "../../notification-popup";
import { ApplePay } from "../apple-pay";
import { GooglePay } from "../google-pay";
import { postGatewayPaymentMessage } from "../../constants/commonUtils";
import moment from "moment";
import debit from "../../assets/svgs/debit-icon.svg";
import credit from "../../assets/svgs/credit-icon.svg";

const CheckoutForm = () => {
  const [timeLeft, setTimeLeft] = useState(300);
  const [focused, setFocused] = useState("");
  const [featureData, setFeatureData] = useState({
    amount: "",
    currency: "",
    is_apple_pay: false,
    is_google_pay: false,
    is_support_3d: false,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    address: "",
    expiry_time: "",
    merchant_invoice_id: "",
    merchant_site_name: "",
    merchant_name: "",
    mode: "TEST",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGooglePaying, setIsGooglePaying] = useState(false);
  const [isApplePaying, setIsApplePaying] = useState(false);

  const [searchParams] = useSearchParams();

  const [mode, setMode] = useState("debit");
  const [showMerchantDetails, setShowMerchantDetails] = useState(true);

  const handleFocused = (e) => {
    setFocused(e.target.name === "cvv" ? "cvc" : e.target.name);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(cardPaymentSchema),
    defaultValues: {
      cardname: "",
      cardnumber: "",
      month: "",
      year: "",
      cvv: "",
      is_google_pay: false,
      is_apple_pay: false,
      is_save_card: false,
    },
  });

  // Handle input change
  const handleChange = (e, name, limit) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/[^\d]/g, "");
    if (
      (name === "month" || name === "year") &&
      sanitizedValue.length <= limit
    ) {
      e.target.value = sanitizedValue;
      setValue(name, value);
      return;
    }
    if (name === "cvc" && sanitizedValue.length <= limit) {
      e.target.value = sanitizedValue;
      setValue("cvv", value);
      return;
    } else {
      e.target.value = sanitizedValue.slice(0, limit);
    }
  };

  const onSubmit = async (values) => {
    if (!isSubmitting) {
      const apiUrl = api_checkout_invoice_url + "wpf/payment";
      setIsSubmitting(true);
      delete values["is_apple_pay"];
      delete values["is_google_pay"];
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...values,
          payment_method: "card",
          c1f7U34t: searchParams.get("c1f7U34t"),
          is_support_3d: featureData.is_support_3d,
        }),
      };

      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsSubmitting(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        postGatewayPaymentMessage(d);
        window.location.href = d.url;

        setIsSubmitting(false);
      } catch (error) {}
    }
  };

  const handleCheckFeature = async () => {
    const apiUrl = api_checkout_invoice_url + "wpf/check-feature";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        // c1f7U34t: "Y2ZkMDhkZGYtZDRlOS00ZTZjLWJkMGItOGFmMTViODIyNjYz",
        c1f7U34t: searchParams.get("c1f7U34t"),
      }),
    };

    try {
      const response = await fetch(apiUrl, requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(response);
      }
      setFeatureData({ ...data.data, country: data.data.country_code || "" });
      if (data.data.email) setValue("email", data.data.email);
      if (data.data.phone) setValue("phone", data.data.phone);
      if (data.data.country_code) setValue("country", data.data.country_code);
      if (data.data.state) setValue("state", data.data.state);
      if (data.data.city) setValue("city", data.data.city);
      if (data.data.postalcode) setValue("postalcode", data.data.postalcode);
      if (data.data.address) setValue("address", data.data.address);
    } catch (error) {}
  };

  useEffect(() => {
    handleCheckFeature();
  }, []);

  const handleGooglePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", true);
    setValue("is_apple_pay", false);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          c1f7U34t: searchParams.get("c1f7U34t"),
          is_support_3d: featureData.is_support_3d,
        }),
      };

      const apiUrl = api_checkout_invoice_url + "wpf/payment";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsGooglePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        postGatewayPaymentMessage(d);
        window.location.href = d.url;

        setIsGooglePaying(false);
      } catch (error) {
        setIsGooglePaying(false);
      }
    }
  };

  const handleApplePay = async (isCallSubmit, body) => {
    setValue("is_google_pay", false);
    setValue("is_apple_pay", true);
    if (isCallSubmit) {
      handleSubmit(onSubmit)(); // Trigger form submission programmatically
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          ...body,
          c1f7U34t: searchParams.get("c1f7U34t"),
          is_support_3d: featureData.is_support_3d,
          // is_support_3d: false // just for testing,
        }),
      };
      const apiUrl = api_checkout_invoice_url + "wpf/payment";
      try {
        const response = await fetch(apiUrl, requestOptions);
        const data = await response.json();
        if (!response.ok) {
          setIsApplePaying(false);
          errorNotification(data?.message);
          throw new Error(response);
        }
        const d = data?.data || data;
        postGatewayPaymentMessage(d);
        window.location.href = d.url;

        setIsApplePaying(false);
      } catch (error) {
        setIsApplePaying(false);
      }
    }
  };

  const handleExpiryChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    let month = "";
    let year = "";

    if (value.length >= 2) {
      month = value.slice(0, 2);
    }
    if (value.length >= 4) {
      year = value.slice(2, 4);
    }

    // Format as MM/YY while typing
    if (value.length > 2) {
      value = `${month}/${year}`;
    }

    // Update the individual fields for month and year
    setValue("month", month);
    setValue("year", year);
  };

  const values = watch();

  return (
    <Fragment>
      <div className="main">
        <div className="box card-div">
          <div className="inner">
            <div className="info-div-sm">
              {showMerchantDetails && (
                <div>
                  <p style={{ fontWeight: 600, fontSize: 20, margin: 0 }}>
                    {featureData.merchant_name}
                  </p>
                  <div className="info-item">
                    <span>Invoice:</span>
                    <p style={{ textAlign: "right", margin: 0 }}>
                      {featureData.merchant_invoice_id}
                    </p>
                  </div>
                  <div className="info-item" style={{ marginTop: 5 }}>
                    <span>Expires in:</span>
                    <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
                  </div>
                  <div className="info-item" style={{ marginTop: 5 }}>
                    <span>Invoice amount:</span>
                    <span>
                      {featureData.amount} {featureData.currency}
                    </span>
                  </div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 18,
                      marginTop: 30,
                      marginBottom: 0,
                    }}
                  >
                    {featureData.first_name} {featureData.last_name}
                  </p>
                  <p className="info-text">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/email.png"}
                      alt="email"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.email}
                  </p>
                  <p className="info-text">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/pngs/phonebook.png"
                      }
                      alt="phonebook"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.phone}
                  </p>
                  <p className="info-text">
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/location.png"}
                      alt="location"
                      style={{ height: 20 }}
                    />{" "}
                    {featureData.address}
                  </p>
                </div>
              )}

              <button
                onClick={() => setShowMerchantDetails(!showMerchantDetails)}
                className="info-btn"
              >
                View invoice and more info
                <img
                  src={
                    showMerchantDetails
                      ? process.env.PUBLIC_URL + "/assets/pngs/chevron-up.png"
                      : process.env.PUBLIC_URL + "/assets/pngs/chevron-down.png"
                  }
                  alt="chevron"
                  style={{ width: 20 }}
                />
              </button>
            </div>

            <p
              style={{
                color: "#8C9199",
                fontWeight: "600",
                margin: "0 auto",
              }}
            >
              PAYMENT AMOUNT
            </p>
            <p style={{ fontSize: 30, marginTop: 0, fontWeight: 600 }}>
              {featureData.amount} {featureData.currency}
            </p>

            <div className="menu-list">
              <button onClick={() => setMode("debit")}>
                <div className="menu-item">
                  <div
                    className={`${mode === "debit" ? "selected" : ""} border`}
                  ></div>
                  <div className="image">
                    <img src={debit} alt="Debit Card Icon" />
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: mode === "debit" ? "#000" : "#8C9199",
                  }}
                >
                  Debit
                </span>
              </button>
              <button onClick={() => setMode("credit")}>
                <div className="menu-item">
                  <div
                    className={`${mode === "credit" ? "selected" : ""} border`}
                  ></div>
                  <div className="image">
                    <img src={credit} alt="Credit Card Icon" />
                  </div>
                </div>
                <span
                  style={{
                    fontSize: 18,
                    fontWeight: 600,
                    color: mode === "credit" ? "#000" : "#8C9199",
                  }}
                >
                  Credit
                </span>
              </button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="input input-lg">
                  <label htmlFor="cardNumber">
                    <span>*</span>Card Number
                  </label>
                  <input
                    name="cardnumber"
                    type="text"
                    id="cardNumber"
                    placeholder="1234 1234 1234 1234"
                    {...register("cardnumber", {
                      onChange: (e) => {
                        handleChange(e, "cardnumber");
                      },
                    })}
                    onFocus={handleFocused}
                  />
                  {errors?.cardnumber?.message && (
                    <p className="error-field">{errors?.cardnumber?.message}</p>
                  )}
                </div>

                <div className="input-group-sm">
                  <div className="input input-sm">
                    <label htmlFor="cardExpiry">
                      <span>*</span>Expiry Date
                    </label>
                    <input
                      name="expiry"
                      type="text"
                      id="cardExpiry"
                      placeholder="MM/YY"
                      maxLength="5"
                      {...register("expiry", {
                        onChange: handleExpiryChange,
                        pattern: {
                          value: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
                          message: "Invalid expiry date format",
                        },
                      })}
                    />
                    {errors?.expiry?.message && (
                      <p className="error-field">{errors?.expiry?.message}</p>
                    )}
                  </div>

                  <div className="input input-sm">
                    <label htmlFor="cardCvc">
                      <span>*</span>CVC
                    </label>
                    <input
                      name="cvc"
                      type="text"
                      id="cardCvc"
                      maxLength={"3"}
                      placeholder="123"
                      {...register("cvv", {
                        onChange: (e) => {
                          handleChange(e, "cvv", 3);
                        },
                      })}
                      onFocus={handleFocused}
                    />
                    {errors?.cvv?.message && (
                      <p className="error-field">{errors?.cvv?.message}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div className="input input-lg">
                  <label htmlFor="cardName">
                    <span>*</span>Card Name
                  </label>
                  <input
                    name="cardname"
                    type="text"
                    id="cardName"
                    placeholder="John Wick"
                    {...register("cardname")}
                    onFocus={handleFocused}
                  />
                  {errors?.cardname?.message && (
                    <p className="error-field">{errors?.cardname?.message}</p>
                  )}
                </div>

                <div className="input-group-sm">
                  <div className="input input-sm">
                    <label htmlFor="country">Country:</label>
                    <select
                      id="country"
                      {...register("country")}
                      disabled={featureData.country ? true : false}
                    >
                      <option value={""}>{"select"}</option>
                      {countryList.map((country, index) => (
                        <option
                          key={`country_${index + 1}`}
                          value={country.value}
                        >
                          {country.label}
                        </option>
                      ))}
                    </select>
                    {errors?.country?.message && (
                      <p className="error-field">{errors?.country?.message}</p>
                    )}
                  </div>

                  <div className="input input-sm">
                    <label htmlFor="postalCode">ZIP Code</label>
                    <input
                      name="postalcode"
                      type="text"
                      id="postalCode"
                      placeholder="Postal Code"
                      {...register("postalcode")}
                      disabled={featureData.postalcode ? true : false}
                    />
                    {errors?.postalcode?.message && (
                      <p className="error-field">
                        {errors?.postalcode?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-row-lg">
                <div className="input input-sm">
                  <label htmlFor="city">City</label>
                  <input
                    name="city"
                    type="text"
                    id="city"
                    placeholder="City"
                    {...register("city")}
                    disabled={featureData.city ? true : false}
                  />
                  {errors?.city?.message && (
                    <p className="error-field">{errors?.city?.message}</p>
                  )}
                </div>
                <div className="input input-sm">
                  <label htmlFor="state">State</label>
                  <input
                    name="state"
                    type="text"
                    id="state"
                    placeholder="State"
                    {...register("state")}
                    disabled={featureData.state ? true : false}
                  />
                  {errors?.state?.message && (
                    <p className="error-field">{errors?.state?.message}</p>
                  )}
                </div>
              </div>

              <p style={{ color: "#8C9199", fontSize: 13 }}>
                By selecting <b>Pay</b>, you accept the{" "}
                <a
                  href={ featureData?.affiliate_email_config?.terms_of_service_link ??
                    featureData?.sub_affiliate_email_config?.terms_of_service_link ??
                     "https://ibanera.com/agreements/Terms-Of-Service_NFT_v2.pdf"}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1520A6",
                    fontWeight: 600,
                  }}
                >
                  Terms of Service
                </a>
                . Our Global{" "}
                <a
                   href={ featureData?.affiliate_email_config?.privacy_statement_link ??
                    featureData?.sub_affiliate_email_config?.privacy_statement_link ??
                     "https://www.ibanera.com/privacy-policy"}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "#1520A6",
                    fontWeight: 600,
                  }}
                >
                  Privacy Statement
                </a>{" "}
                will apply to any personal information you provide us. You also
                allow us to charge{" "}
                <b>
                  {featureData.amount} {featureData.currency}
                </b>{" "}
                to your card on {moment().format("MMMM Do YYYY")}
              </p>

              <div style={{ display: "flex" }}>
                <input
                  type="checkbox"
                  id="save_card"
                  {...register("is_save_card")}
                />
                <label
                  htmlFor="save_card"
                  style={{ color: "#8C9199", fontSize: 13 }}
                >
                  Save this card for future purchases
                </label>
              </div>

              <button
                className="checkoutBtn"
                disabled={isSubmitting || !timeLeft}
                onClick={() => {
                  setValue("is_google_pay", false);
                  setValue("is_apple_pay", false);
                }}
                style={{
                  pointerEvents: isSubmitting || !timeLeft ? "none" : "auto",
                }}
              >
                {isSubmitting ? "Paying..." : "Pay"} {featureData.amount}{" "}
                {featureData.currency}
              </button>

              <div className="paymentMethodDiv">
                <GooglePay
                  featureData={featureData}
                  isLoading={isGooglePaying}
                  setIsLoading={setIsGooglePaying}
                  handleGooglePayClick={handleGooglePay}
                  is_google_pay={featureData.is_google_pay}
                  values={{}}
                />
                <ApplePay
                  featureData={featureData}
                  isLoading={isApplePaying}
                  setIsLoading={setIsApplePaying}
                  handleApplePayClick={handleApplePay}
                  is_apple_pay={featureData.is_apple_pay}
                  values={{}}
                  is_support_3d={featureData.is_support_3d}
                />
              </div>

              <p
                style={{
                  color: "#8C9199",
                  fontSize: 13,
                  textAlign: "center",
                  marginTop: 30,
                }}>
                {featureData?.affiliate_email_config?.footer_note ??
                 featureData?.sub_affiliate_email_config?.footer_note ??
                  "2025 © Ibanera LLC. All rights reserved."}
              </p>
            </form>
          </div>
        </div>
        <div className="info-div">
          <div className="box">
            <div className="inner">
              <p style={{ fontWeight: 600, fontSize: 20, marginTop: 0 }}>
                {featureData.merchant_name}
              </p>
              <div className="info-item">
                <span>Invoice:</span>
                <p style={{ textAlign: "right", margin: 0 }}>
                  {featureData.merchant_invoice_id}
                </p>
              </div>
              <div></div>
              <div className="info-item" style={{ marginTop: 10 }}>
                <span>Expires in:</span>
                <Timer timeLeft={timeLeft} setTimeLeft={setTimeLeft} />
              </div>
              <div className="info-item" style={{ marginTop: 10 }}>
                <span>Invoice amount:</span>
                <span>
                  {featureData.amount} {featureData.currency}
                </span>
              </div>
            </div>
          </div>
          <div className="box" style={{ paddingBottom: 10 }}>
            <div className="inner">
              <p style={{ fontWeight: 600, fontSize: 18, margin: 0 }}>
                {featureData.first_name} {featureData.last_name}
              </p>
              <p style={{ marginTop: 0 }}>Email: {featureData.email}</p>
              <button
                onClick={() => setShowMerchantDetails(!showMerchantDetails)}
                style={{
                  border: 0,
                  background: "transparent",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <img
                  src={
                    showMerchantDetails
                      ? process.env.PUBLIC_URL + "/assets/pngs/chevron-up.png"
                      : process.env.PUBLIC_URL + "/assets/pngs/chevron-down.png"
                  }
                  alt="chevron"
                  style={{ width: 20 }}
                />
              </button>

              {showMerchantDetails && (
                <div>
                  <p
                    style={{
                      marginTop: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/pngs/phonebook.png"
                      }
                      alt="phonebook"
                      style={{ height: 30 }}
                    />{" "}
                    {featureData.phone}
                  </p>
                  <p
                    style={{
                      marginTop: 0,
                      display: "flex",
                      alignItems: "center",
                      gap: 5,
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/assets/pngs/location.png"}
                      alt="location"
                      style={{ height: 30 }}
                    />{" "}
                    {featureData.address}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutForm;

const Timer = ({ timeLeft, setTimeLeft }) => {
  useEffect(() => {
    if (timeLeft <= 0) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  return <span>{timeLeft ? formatTime(timeLeft) : "Session Expired"}</span>;
};
